function BanglaTelevision() {
  return (
    <div style={{ padding: "16px" }}>
      <h2>PRIVACY POLICY </h2>
      <p>
        Bangla Television is committed to protect your data when you are using
        the Bangla Television app on your phone. The Privacy Policy applies only
        when the contents are generated by Bangla Television.
      </p>
      <p>
        Any personal information such as your name, address, phone number, email
        address, age, social network account etc. are never collected.
      </p>
      <h3>Advertisement</h3>
      <p>
        The advertisements included in the Bangla Television app are by
        thirdparty companies & ad networks through independent ad tags, which
        may collect information about users for which Bangla Television shall
        bear no responsibility that may arise as a result of collecting and/or
        sharing the information’s with any other party.
      </p>
      <p>
        Bangla Television shall not accept any liability that may arise as a
        result of any content of any advertisement that may appear on the Bangla
        Television app.
      </p>
      <h3>Use of Cookies</h3>
      <p>
        Bangla Television does not collect any user data based on cookies,
        neither does it store any sort of user information that may be personal
        to the user.
      </p>
      <h3>Governing Law</h3>
      <p>
        The laws that govern Privacy Policy of Bangla Television and its
        relationship with the user is the laws of Bangladesh and any dispute
        regarding the use, retention, disclosure, leakage or dissemination of
        the information or date can only be raised before the courts of
        Bangladesh which shall have exclusive jurisdiction on this matter. The
        entire Privacy Policy shall apply to all who use application from Bangla
        Television regardless of their nationality, location, residence or place
        of business.
      </p>
      <h3>Modification of Privacy Policy</h3>
      <p>
        Bangla Television reserves the right to amend, modify, alter, or omit
        any terms in the Privacy Policy at any time but the changed policy shall
        be immediately uploaded or updated in the website. By continuing to use
        our services after any changes are made, you accept those changes and
        will be bound by them. We encourage you to periodically check back and
        review this policy so that users will always know what information we
        collect and how we use it.
      </p>
    </div>
  )
}

export default BanglaTelevision
