import Banner from "../assets/banner_big.jpg"

function Home() {
  return (
    <div>
      <img src={Banner} alt="Banner" width={"100%"}></img>
    </div>
  )
}

export default Home
