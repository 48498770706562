import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom"
import Home from "./components/Home"
import BanglaTelevision from "./components/BanglaTelevision"
import BanglaRadio from "./components/BanglaRadio"

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route
            path="/bangla-television"
            element={<BanglaTelevision />}
          ></Route>
          <Route path="/bangla-radio" element={<BanglaRadio />}></Route>
          <Route path="/" element={<Home />}></Route>
        </Routes>
      </div>
    </Router>
  )
}

export default App
